export const locale = {
  lang: 'en',
  data: {
    REGISTRATION: {
      PERSONAL_INFO: {
          RELIGION: 'Religion',
          CIVIL_STATUS: 'Civil Status',
          NO_OF_DEPENDENTS: 'No. of Dependents',
          TELEPHONE: 'Telephone Number',
          BIRTHDATE: 'Birth Date',
          AGE: 'Age',
          SPOUSE_NAME: 'Spouse\'s Name',
          SPOUSE_OCCUPATION: 'Spouse\'s Occupation',
          DATE_OF_MARRIAGE: 'Date of Marriage',
          DATE_OF_MEDICAL: 'Date of Medical',
          FATHER_NAME: 'Father\'s Name',
          FATHER_OCCUPATION: 'Father\'s Occupation',
          MOTHER_MAIDEN_NAME: 'Mother\'s Maiden Name',
          MOTHER_OCCUPATION: 'Mother\'s Occupation'
      },
      WORK: {
        OCCUPATION: 'Occupation',
        SALARY: 'Salary',
        OFW: 'OFW',
      },
      WAIVER: {
        MEMBER_AGREEMENT: 'Kasunduan Pagsapi at Subskripsyon sa Kapital',
        MEMBERSHIP_STATEMENT: 'Pahayag sa Pagsapi',
        WAIVER: 'Kasulatan Hingil sa Hindi Pagtanggap ng Benepisyong Medikal'
      },
      DECLARATION: {
        TAB_TITLE: 'Declaration'
      }
    },
    MENU: {
      HOME: 'Home',
      DASHBOARD: 'Dashboard',
      PURCHASES: 'Purchases',
      SHARE_CAPITAL: 'Share Capital',
      MEMBER_TRANSACTIONS: 'Transactions',
      LOANS: 'Loans',
      INVESTMENTS: 'Investments',
      CHANGE_REQUEST: 'Update Request',
      MEMBERS_REPORT: 'Members Report',
      DIGITAL_ID:'Digital ID',
      CONTACT_US: 'Contact Us',
      APPLICANTS: 'Applicants',
      LEADS: 'Leads',
      MEMBERS: 'Members',
      EVENTS: 'Seminars',
      VOUCHERS: 'Vouchers',
      SETTINGS: 'Settings',
      ANNOUNCEMENTS: 'Announcements',
      REPORTS: 'Reports',
      USERS: 'Users',
      SETTINGS_LANGUAGE: 'Language',
      SETUP: 'Setup',
      SUPER_ADMIN_SETUP: 'Super Admin Setup',
      FINANCIALS: 'Financials',
      DIVIDEND: 'Dividend'
    },
    SAMPLE: {
      AWESOME: 'Create Awesome 🙌'
    }
  }
}